import AOS from 'aos'
import Layout from '../components/Layout';
import React, { PureComponent } from "react";
import WhereSection from '../components/WhereSection';
import withLoading from '../hocs/withLoading'

import styles from './styles.module.css'

class WherePage extends PureComponent {
    componentDidMount = () => {
        AOS.init({
            duration: 700,
            offset: 120,
        })
    }

    render() {
        const { pageContext, loading } = this.props

        return (
            <Layout pageContext={pageContext} loading={loading} location={this.props.location}>
                <div 
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      className={styles.background}
                >
                    <WhereSection/>
                </div>
            </Layout>
        )
    }
}

export default withLoading(WherePage, styles.background)